/**
 * FooterPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 *
 */

import React from 'react';
import { Link } from 'react-router-dom';

import { Content } from './styledComponents';

export default function Footer() {
  return (
    <Content>
      <div>
        <Link to="/">
          <img
            src="./images/logo-white.svg"
            alt="Logo PrestaVale blanco"
            className="logo"
          />
        </Link>
      </div>
      <div className="options-section">
        <div>
          <Link to="/nosotros">Nosotros</Link>
          <Link to="/conviertete-en-distribuidora">
            ¿Quieres ser distribuidora?
          </Link>
          <Link to="/distribuidoras-movil">Distribuidoras móvil</Link>
          <Link to="/inversionistas">Inversionistas</Link>
          <Link to="/sucursales">Sucursales</Link>
        </div>
        <div>
          <a href="./terminos-y-condiciones.pdf">Términos y condiciones</a>
          <a href="/aviso-privacidad">Aviso de privacidad</a>
          <a
            target="_blank"
            href="https://storage.googleapis.com/prestavale-static/BASES-DINAMICA-CONV2024.pdf"
          >
            Dinámica ¡Coloca y gana un viaje!
          </a>
          <a href="/captacion-nuevas-cuentas">
            Dinámica de Captación de Nuevas Cuentas
          </a>
        </div>
        <div>
          <div className="download-label">Descarga nuestra aplicación en</div>
          <a
            href="http://play.google.com/store/apps/details?id=mx.roket.mada.distribuidoras"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="app-img"
              alt="Google play"
              src="./images/play-store.png"
            />
          </a>
          <a
            href="https://apps.apple.com/mx/app/distribuidoras-m%C3%B3vil-corp-150/id1499742551"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="app-img"
              alt="App store"
              src="./images/app-store.png"
            />
          </a>
        </div>
      </div>
    </Content>
  );
}
